import React from 'react';

import { CiBank, } from "react-icons/ci";
import { LuBanknote } from "react-icons/lu";
import { MdFlightTakeoff, MdVerifiedUser } from "react-icons/md";
import {
    FaShieldAlt, FaArrowRight
} from "react-icons/fa";





const OurServices = () => {
    const products = [
        {
            img:CiBank ,
            name: "Banking",
            link: "/aadhaar_enabled_payments"
        },
        {
            img:  LuBanknote ,
            name: "Payments",
            link: "/domestic_money_transfer"
        },
        {
            img: MdFlightTakeoff ,
            name: "Travel",
            link: "/mobile_dTH_recharges"
        },
        {
            img: FaShieldAlt ,
            name: "Insurance",
            link: "/bharat_bill_payments"
        },
        {
            img: MdVerifiedUser ,
            name: "Other",
            link: "/pan_card_issuance"
        },
        // {
        //     img: "image/5.jpg",
        //     name: "Motor Insurance",
        //     link: "/motor_insurance"
        // }, {
        //     img: "image/7.jpg",
        //     name: "Personal Loans",
        //     link: "/personal_loans"
        // }
        // , {
        //     img: "image/8.jpg",
        //     name: "Electricity Bill Payments ",
        //     link: "/ele_bill_payment"
        // }, {
        //     img: "image/9.jpg",
        //     name: "Micro-ATM",
        //     link: "/micro-atm"
        // }, {
        //     img: "image/6.jpg",
        //     name: "WHITE - Label Software",
        //     link: "/white_label_software"
        // }
      

        
    ];
    return (
        <div className='mb-10  bg-[#F2F2F2] min-h-[200px]'>
            <div className='w-full underline   mx-auto mt-10 font-sans font-bold text-[36px]  text-center text-[#194086]'>
                <span className=' font-sans  font-bold text-[36px] text-[#39b549]'>Our</span> Services
            </div>
            <div className=' w-full px-5  md:mt-[20px] mt-[40px]  flex md:justify-between md:items-center flex-wrap md:flex-row flex-col justify-center items-center'>
                {
                    products.map((item,index)=>{
                       return <div style={{borderRadius:'6px'}} className='border  mb-10 w-full md:w-[210px] h-[210px] hover:bg-[#194086] hover:text-[white]  flex flex-col justify-center items-center  shadow-lg duration-200	 mydesign'>
                        <div className='w-[79px] p-4 h-[79px] flex justify-center items-center   border border-[#39B549] myborder mt-5 justify-items-center rounded-full reletive' >
                           
                        <item.img
                      className='w-[50px] text-[#39B549] h-[50px]
                      key={index}'/>
                        </div>
                        <div className='mt-6 myname text-[#194086] font-bold text-[20px]'>{item.name}</div>
                        <div className='my-4 hover:gap-1 duration-200 cursor-pointer flex gap-3'><FaArrowRight className='mt-2 ' /> <span className='text-[20px] font-bold mymore  text-black'>know more</span></div>
                    </div>
                    })
                }
               
            </div>
        </div>
    );
}

export default OurServices