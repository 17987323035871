import React from 'react';
import { FaUserCheck } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa6";
import { FaUserLarge } from "react-icons/fa6";
import { FaExchangeAlt } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCircleNotch } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";


const ClintsData = () => {
    const products = [
        {
            img: <FaUserCheck className='w-[56px] h-[45px] text-[green]' />,
            name: "10 Lakhs+",
            tag:"Retailers"
            
        },
        {
            img: <FaUserTie className='w-[56px] h-[45px] text-[green]' />,
            name: "5000+",
            tag:"Channel Partners"

           
        },
        {
            img: <FaUserLarge className='w-[56px] h-[45px] text-[green]' />,
            name: "5 Crore+",
            tag:"Customers"

            
        },
        {
            img: <FaExchangeAlt className='w-[56px] h-[45px] text-[green]' />,
            name: "3 Lakhs+",
            tag:"Daily Transactions"

          
        },
        {
            img: <FaRegCheckCircle className='w-[56px] h-[45px] text-[green]' />,
            name: "720",
            tag:"Districts"

        },{
            img: <FaCircleNotch className='w-[56px] h-[45px] text-[green]' />,
            name: "28",
            tag:"States"

        },{
            img: <FaCogs className='w-[56px] h-[45px] text-[green]' />,
            name: "50+",
            tag:"Services"

        },
       
    ];
    return (
        <div  className='w-full mt-6 bg-[#F2F2F2] gap-4 px-4  grid md:grid-cols-7 grid-cols-1'>
           
               
                   {
                    products.map((item,index)=>{
                return (
                    <div className='min-w-[102px] min-h-[176px]'>
                    <ul className='flex justify-center py-4 gap-4 flex-col items-center'>
                     <li >{item.img}</li>
                <li className='text-[25px] text-[#194086] font-bold'>{item.name}</li>
                <li className='text-[15px] text-[#000000] font-bold'>{item.tag}</li>
                </ul>
                </div>
                )
                    })
                   }
               
         
           
            
        </div>
    );
}

export default ClintsData;
