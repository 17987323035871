import React from 'react';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Main from './pages/Main';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Testmonial from './pages/Testmonial';
import Micro_Atm from './pages/service/Micro_Atm'
import Adharcard_Payment from './pages/service/Adharcard_Payment'
import Bharat_Bill_Payment from './pages/service/Bharat_Bill_Payment'
import Domestic_Money from './pages/service/Domestic_Money'
import Mobile_Recharges from './pages/service/Mobile_Recharges'
import Motor_insurance from './pages/service/Motor_insurance'
import Pan_Insurance from './pages/service/Pan_Insurance'
import Persanal_Loan from './pages/service/Persanal_Loan'
import White_Software from './pages/service/White_Software'
import Electricity_Bill from './pages/service/Electricity_Bill'
import Services from './pages/Services'
import Vision from './pages/Vision';
import Careers from './pages/Careers';


const routes = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: '/about',
        element: <About/>
      },
      {
        path: '/contact',
        element: <Contact/>
      } ,{
        path: '/services',
        element: <Services/>
      } 
      ,{
        path: '/micro-atm',
        element: <Micro_Atm/>
      } ,{
        path: '/aadhaar_enabled_payments',
        element: <Adharcard_Payment/>
      } ,{
        path: '/bharat_bill_payments',
        element: <Bharat_Bill_Payment/>
      } ,{
        path: '/domestic_money_transfer',
        element: <Domestic_Money/>
      } ,{
        path: '/mobile_dTH_recharges',
        element: <Mobile_Recharges/>
      } ,{
        path: '/motor_insurance',
        element: <Motor_insurance/>
      } ,{
        path: '/pan_card_issuance',
        element: <Pan_Insurance/>
      } ,{
        path: '/personal_loans',
        element: <Persanal_Loan/>
      } ,{
        path: '/services',
        element: <White_Software/>
      } ,{
        path: '/ele_bill_payment',
        element: <Electricity_Bill/>
      } ,{
        path: '/white_label_software',
        element: <White_Software/>
      } ,{
        path: '/testmonial',
        element: <Testmonial/>
      } ,{
        path: '/vision_mission',
        element: <Vision/>
      }  ,{
        path: '/careers',
        element: <Careers/>
      } 
    ]
  },
  
]
)

const App =()=> {
  return (

     <RouterProvider router={routes} />

  )
}

export default App;
