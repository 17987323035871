import React from 'react';



const Pan_Insurance = () => {
    return (
        <div className='px-6 mycontainer  md:px-0 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/4.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
         
                
            <span className="text-mygreen">PAN card</span> 
    <span className="text-myblue">     issuance Centre</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">Start Making Pan Cards for your customers online
Now make new Pan Cards or get it updated through government authorized channels. Our fast and secure network provides a seamless service making the whole process quick & hassle for your customers.
We at SecurePe provides both entities (NSDL & UTIITSL) Service of PAN card issuance at our portal</p>

            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
            <span className="text-mygreen">PAN card</span> 
    <span className="text-myblue">     issuance Centre</span>
          </p>
                <hr />
                <p className='text-sm text-[#777]'>    <p style={{lineHeight:'45px'}} className="text-sm text-black ">Start Making Pan Cards for your customers online
Now make new Pan Cards or get it updated through government authorized channels. Our fast and secure network provides a seamless service making the whole process quick & hassle for your customers.
We at SecurePe provides both entities (NSDL & UTIITSL) Service of PAN card issuance at our portal</p></p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/4.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default Pan_Insurance;





