import React from 'react';

const Vision = () => {
    return (
        <div>
            <div>
                <img src="image/vision-mision.jpg" alt="" />
            </div>
            <div className='w-full mt-10 px-20'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
<div >
    <h1 className='text-[36px] text-[#194086] font-bold'>vision</h1>
    <p  className='text-[20px] text-black'>Make digital financial services available to everyone, everywhere. We believe in making life simple for our retailers and their customers.</p>
</div>
<div >
    <h1 className='text-[36px] text-[#194086] font-bold'>Mission</h1>
    <p className='text-[20px] text-black'>Build the largest branchless digital banking network that helps create a more progressive society through easy access to financial services.</p>
</div>

            </div>
            <div style={{lineHeight:'34px'}} className='text-justify text-[16px] text-black flex flex-col gap-10 mt-20'>
    <p><b>Digitalisation:</b>  SecurePay aims to contribute to accelerating the digital revolution, especially in rural areas, given the extensive market which rests there. One of the unique features of digital payments has been their ability to access the areas that even banks could not access. Though SecurePay  digitalisation campaign is yet to reach its optimum potential, our motto is to encourage smooth and easy digital transactions in remote parts of the country.
</p>
<p>
    
<b>Financial Inclusion:</b> Among the ripple effects of digitalisation lies the financial empowerment of both men and women, boosting the economy and the country’s Gross Domestic Product (GDP). India, which was otherwise hugely cash-driven, is gradually transitioning towards a cashless and paperless economy. It has been made possible with the years of groundwork and initiatives sponsored by the Government and fintech companies in India like SecurePay. Today SecurePay provides an array of digital financial services targeted towards rural India. Thus with the broader and deeper spread of digital empowerment, India would soon be able to bridge the existing digital gap and capitalise on the unexplored market.

</p>
<p>
<b>Sustainability:</b> Following the pandemic-led behavioral shift in consumers paired with the digitalization of Kirana stores, new payment frameworks have gained a stronghold in the country in response to the rising demand for digital payment solutions. With the people going back to normalcy, leading to increased consumer demand, it is evident that this new financial landscape is here to stay. As the economies return on track, going digital is not only an easy solution for the consumers and retailers, but it is a vital part of the road to economic recovery. As per the existing technologies, all trends of making digital payments are considered safe and hassle-free. People are adopting these trends with open arms. In the foreseeable future, we will witness the full potential of these trends and how they develop.
</p>
</div>
        </div>
        </div>
    );
}

export default Vision;
