import React from 'react';


const Domestic_Money = () => {
    return (
        <div className='px-6  mycontainer md:px-4 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/2.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg capitalize font-bold text-[#000]">
           
         <span className="text-mygreen">  Domestic</span> 
    <span className="text-myblue"> Domestic Money Transfer </span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">The need of transferring money can arise at anytime. It often arises during non-banking hours. SecurePe Money Transfer allows you to send money instantly beyond banking hours to any IMPS & NEFT supported bank in India. The sender can send money through any SecurePe retail outlet across India with ease. The money sent to the beneficiary is credited to their bank account within 10 seconds. These transactions done by customers are processed through highly secured servers. Customers can pay the transaction amount to the retailers through any mode. SecurePe facilitates money remittance as per RBI approved guidelines and PPI & Banking Correspondent license holder</p>
            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
             
            <span className="text-mygreen">  Domestic</span> 
    <span className="text-myblue"> Domestic Money Transfer </span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">The need of transferring money can arise at anytime. It often arises during non-banking hours. SecurePe Money Transfer allows you to send money instantly beyond banking hours to any IMPS & NEFT supported bank in India. The sender can send money through any SecurePe retail outlet across India with ease. The money sent to the beneficiary is credited to their bank account within 10 seconds. These transactions done by customers are processed through highly secured servers. Customers can pay the transaction amount to the retailers through any mode. SecurePe facilitates money remittance as per RBI approved guidelines and PPI & Banking Correspondent license holder</p>
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/2.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default Domestic_Money;




