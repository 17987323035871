import React from 'react';



const Bharat_Bill_Payment = () => {
    return (
        <div className='px-6  mycontainer md:px-4 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/12.jpg" alt="" /></div>
            <div className='flex px-2 mt-10 md:mt-0 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
         
            <span className="text-mygreen">  Bharat Bill </span> 
         <span className="text-myblue"> Payment System</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">SecurePe portal allows its retailers to pay utility bills such as Electricity, Water, Gas, Fixed-line, Municipal Taxes etc. on behalf of the customers. They can walk up to their nearest SecurePe retail touchpoint to use this service. As SecurePe is a registered agent institution under Bharat Bill Payment System (BBPS), customers can avail these services for extended hours through its retail outlets. The transaction takes place in real time and immediately reflects in customer's account. The convenience of paying multiple utility bills at one place gives our customers utmost satisfaction.</p>
                
            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
       
         <span className="text-mygreen">  Bharat Bill </span> 
         <span className="text-myblue"> Payment System</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">SecurePe portal allows its retailers to pay utility bills such as Electricity, Water, Gas, Fixed-line, Municipal Taxes etc. on behalf of the customers. They can walk up to their nearest SecurePe retail touchpoint to use this service. As SecurePe is a registered agent institution under Bharat Bill Payment System (BBPS), customers can avail these services for extended hours through its retail outlets. The transaction takes place in real time and immediately reflects in customer's account. The convenience of paying multiple utility bills at one place gives our customers utmost satisfaction.</p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/12.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default Bharat_Bill_Payment;
