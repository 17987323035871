import React from 'react';



const Motor_insurance = () => {
    return (
        <div className='px-6 mycontainer  md:px-4 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/2.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
              
            <span className="text-mygreen">Motor</span> 
    <span className="text-myblue"> Insurance</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">SecurePe works closely with its service partner to offer need-based insurance products such as four- wheeler and two-wheeler insurance to target the customers. Customers can avail this facility by reaching their nearest retail outlet. SecurePe is a Master POS Agent & authorized to provide insurance solutions in India. SecurePe offers this service to those customers who are generally in the high risk category or cannot afford the high premiums..</p>

            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
             
            <span className="text-mygreen">Motor</span> 
    <span className="text-myblue"> Insurance</span>
          </p>
                <hr />
                    <p style={{lineHeight:'45px'}} className="text-sm text-black ">SecurePe works closely with its service partner to offer need-based insurance products such as four- wheeler and two-wheeler insurance to target the customers. Customers can avail this facility by reaching their nearest retail outlet. SecurePe is a Master POS Agent & authorized to provide insurance solutions in India. SecurePe offers this service to those customers who are generally in the high risk category or cannot afford the high premiums.</p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/2.jpg" alt="" /></div>
        </div>

    </div>
    );}

export default Motor_insurance;




