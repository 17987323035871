import React from 'react';



const Persanal_Loan = () => {
    return (
        <div className='px-6 mycontainer  md:px-0 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/6.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
             
            <span className="text-mygreen">Personal</span> 
    <span className="text-myblue"> Loans</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">SecurePe helps merchants grow their business by applying for loans with the click of a button. This intelligent loan platform, developed by implementing Artificial Intelligence, uses fully integrated Aadhar & India Stack capabilities to achieve paperless disbursement.
SecurePe's strategic tie-ups with various NBFCs & Technological companies makes the processing of loan easier and seamless through its platform.</p>

            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
            <span className="text-mygreen">Personal</span> 
    <span className="text-myblue"> Loans</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">SecurePe helps merchants grow their business by applying for loans with the click of a button. This intelligent loan platform, developed by implementing Artificial Intelligence, uses fully integrated Aadhar & India Stack capabilities to achieve paperless disbursement.
SecurePe's strategic tie-ups with various NBFCs & Technological companies makes the processing of loan easier and seamless through its platform.</p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/6.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default Persanal_Loan;





