import React from 'react';

import { CiBank, } from "react-icons/ci";
import { LuBanknote } from "react-icons/lu";
import { MdFlightTakeoff, MdVerifiedUser } from "react-icons/md";
import {
    FaShieldAlt, 
} from "react-icons/fa";





const Careers = () => {
    const products = [
        {
            img:CiBank ,
            name: "Non-Stop Growth",
           
        },
        {
            img:  LuBanknote ,
            name: "Great Benefits",
            
        },
        {
            img: MdFlightTakeoff ,
            name: "Timely Training",
            
        },
        {
            img: FaShieldAlt ,
            name: "Best Culture",
          
        },
        
        
    ];
    return (
        <div className='mb-10 px-20  bg-[#F2F2F2] min-h-[200px]'>
            <img className='h-300px' src="image/career.jpg" alt="" />
            <div className='w-full underline   mx-auto mt-10 font-sans font-bold text-[36px]  text-center text-[#194086]'>
                <span className=' font-sans  font-bold text-[36px] text-[#39b549]'>Why </span> Join Us
            </div>
            <div className=' w-full px-5  md:mt-[20px] mt-[40px]  flex md:justify-between md:items-center flex-wrap md:flex-row flex-col justify-center items-center'>
                {
                    products.map((item,index)=>{
                       return <div style={{borderRadius:'6px'}} className='border  mb-10 w-full md:w-[210px] h-[210px] hover:bg-[#194086] hover:text-[white]  flex flex-col justify-center items-center  shadow-lg duration-200	 mydesign'>
                        <div className='w-[79px] p-4 h-[79px] flex justify-center items-center   border border-[#39B549] myborder mt-5 justify-items-center rounded-full reletive' >
                           
                        <item.img
                      className='w-[50px] text-[#39B549] h-[50px]
                      key={index}'/>
                        </div>
                        <div className='mt-6 myname text-black font-bold font-bold text-[20px]'>{item.name}</div>
                       
                    </div>
                    })
                }
               
            </div>
            <p className='text-center text-[20px]'>If you have the right skill-set and passion to take SecurePay to new heights then share your resume with us at hr.Info@securepe.in</p>
        </div>
    );
}

export default Careers;