import React from 'react';
import Middle from '../camponent/Middle'

const Services = () => {
    return (
        <>
           <Middle/>
        </>
    );
}

export default Services;
