import React from 'react';
import Carousel from '../camponent/Carousel'
import Middle from '../camponent/Middle'
import Gallery from '../camponent/Gallery';
import OurServices from '../camponent/OurServices';
import Benefit from '../camponent/Benefit'
import Testmonial from '../pages/Testmonial'
import ClintsData from '../camponent/ClintsData';
const Home = () => {
    return (
        <>
          <Carousel/>
          <OurServices/>
          <Benefit/>
        <Testmonial/>
          <ClintsData/> 
         
        </>
    );
}

export default Home;
