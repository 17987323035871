import React from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { MdCall } from "react-icons/md";
import { SiMinutemailer } from "react-icons/si";
import { PiWhatsappLogoBold } from "react-icons/pi";




const Contacts = () => {
  return (
    <>
     <div className='w-full  mb-[30px] px-10'>
      <div style={{borderBottom:'4px double black'}} className='flex font-bold pb-4 w-[200px] gap-2'>
        <span className='text-[36px] text-[#39b549]'>Get</span>
        
        <span className='text-[#194086] text-[36px]'>inTouch</span>
      </div>
      <div>
      <p className='text-[#5f5f5f] text-[17px] my-10'>If you are interested in finding out more about how we can help, please fill out the form below and <br />
our sales representative will reach out to you in 24 Hours.</p>
      </div>
      <div className='grid md:grid-cols-2 gap-6 grid-cols-1'>
<div className='h-[500px] mb-10 md:mb-0'>

  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113851.98327843289!2d75.67919284335937!3d26.9074785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db6957c272f35%3A0xb2451814a4d77ef2!2sDirection!5e0!3m2!1sen!2sin!4v1701171992562!5m2!1sen!2sin" className='w-full h-[100%]'
                    allowfullscreen></iframe>
  

</div>
<div>
  <h1 className='w-full text-[#194086] text-[22px] md:text-[26px]' style={{borderBottom:'4px double red'}}>SecurePay Head Office</h1>
  <div >
    <div className='flex mb-10  gap-16'>
      <div className='bg-[#55BB531A] flex mt-2 justify-center items-center w-[50px] h-[40px] rounded-full'><FaLocationDot className='text-[30px] ' /></div>
      <div className='mt-4 '>
        <h1 className='text-black font-semibold'>SecurePay Digital Services Private Limited</h1>
        (Formerly known as "SecurePay & SecurePay Utility Services Pvt Ltd.")
        <p>46, 3rd Floor, Precious Mall, MD Road, Jaipur, Rajasthan - 302004</p>
       
      </div>
    </div>
    <div className='flex mb-10  gap-16'>
      <div className='bg-[#55BB531A] flex mt-2 justify-center items-center w-[50px] h-[40px] rounded-full'><MdCall
 className='text-[30px] ' /></div>
      <div>
        <h1 className='text-black font-semibold'>Call Us</h1>
        
        <p>+91-8952996656</p>
       
      </div>
    </div>
    <div className='flex mb-10  gap-16'>
      <div className='bg-[#55BB531A] flex mt-2 justify-center items-center w-[50px] h-[40px] rounded-full'><SiMinutemailer
 className='text-[30px] ' /></div>
      <div>
        <h1 className='text-black font-semibold'>Write to us at</h1>
       
        <p>Info@securepe.in</p>
       
      </div>
    </div>
    <div className='flex  gap-16'>
      <div className='bg-[#55BB531A] flex mt-2 justify-center items-center w-[50px] h-[40px] rounded-full'><PiWhatsappLogoBold
 className='text-[30px] ' /></div>
      <div>
        <h1 className='text-black font-semibold'>Say 'Hi' at Whatsapp</h1>
        
        <p>+91-8952996656</p>
        
      </div>
    </div>
  </div>
  
</div>
      </div>
     
    
    </div>
   
    </>
   
    
  );
}

export default Contacts;
