import React from "react";

const Micro_Atm = () => {
  return (
    <div className="px-6 mycontainer md:px-4 mb-8">
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] myshadow  sm:w-[200px] flex justify-center items-center">
          <img src="image/9.jpg" alt="" />
        </div>
        <div className="flex px-2 mt-10 md:mt-0 flex-col gap-3">
          <p className="text-lg font-bold capitalize text-[#000]">
          <span className="text-mygreen"> FINTECH</span> 
    <span className="text-myblue">       Micro-ATM</span>
          </p>
          <hr />
          <p style={{lineHeight:'45px'}} className="text-sm text-black ">
          ➡ Became an ATM & allow anybody to withdraw money through swiping debit card 
          It often happens that when we are running low on cash to make payments, we can't find an ATM nearby. To cater to this problem, SecurePe offers you a cash withdrawal solution through its range of Retail outlets. Whether it is to make payment for the goods or services you purchase or to withdraw cash from the retail store, mPOS/MICRO ATM Device from SecurePe makes all of this possible with great amount of convenience. You can withdraw up to 10,000 from this device by swiping any bank's Debit/ATM card. This ultraportable device can be used to enable card acceptance at the customers doorstep as well. The only thing that the Retailers need to do is to get SecurePe's mPOS/MICRO ATM Device and they are ready to make the transactions.
          </p>
        
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg font-bold capitalize text-[#000]">
        
          <span className="text-mygreen"> FINTECH</span> 
    <span className="text-myblue">       Micro-ATM</span>
          </p>
          <hr />
          <p style={{lineHeight:'45px'}} className="text-sm text-black ">
          ➡ Became an ATM & allow anybody to withdraw money through swiping debit card 
          It often happens that when we are running low on cash to make payments, we can't find an ATM nearby. To cater to this problem, SecurePe offers you a cash withdrawal solution through its range of Retail outlets. Whether it is to make payment for the goods or services you purchase or to withdraw cash from the retail store, mPOS/MICRO ATM Device from SecurePe makes all of this possible with great amount of convenience. You can withdraw up to 10,000 from this device by swiping any bank's Debit/ATM card. This ultraportable device can be used to enable card acceptance at the customers doorstep as well. The only thing that the Retailers need to do is to get SecurePe's mPOS/MICRO ATM Device and they are ready to make the transactions.

          </p>
         
        </div>
        <div className="md:w-[400px] mt-10 md:mt-0  md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="image/9.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Micro_Atm;

