import { useState } from 'react';

import { Link } from 'react-router-dom';


const Seller = () => {
    const products = [
        {
            img: "image/1.jpg",
            name: "Aadhaar Enabled Payments ",
            link: "/aadhaar_enabled_payments"
        },
        {
            img: "image/2.jpg",
            name: "Domestic Money Transfer",
            link: "/domestic_money_transfer"
        },
        {
            img: "image/3.jpg",
            name: "Mobile & DTH Recharges",
            link: "/mobile_dTH_recharges"
        },
        {
            img: "image/12.jpg",
            name: "Bharat Bill Payments ",
            link: "/bharat_bill_payments"
        },
        {
            img: "image/4.jpg",
            name: "PAN card issuance Centre",
            link: "/pan_card_issuance"
        },
        {
            img: "image/5.jpg",
            name: "Motor Insurance",
            link: "/motor_insurance"
        }, {
            img: "image/7.jpg",
            name: "Personal Loans",
            link: "/personal_loans"
        }
        , {
            img: "image/8.jpg",
            name: "Electricity Bill Payments ",
            link: "/ele_bill_payment"
        }, {
            img: "image/9.jpg",
            name: "Micro-ATM",
            link: "/micro-atm"
        }, {
            img: "image/6.jpg",
            name: "WHITE - Label Software",
            link: "/white_label_software"
        }
      

        
    ];



    return (
        <div className='mycontainer  px-10'>
  <div className='bg-[white]'>
    <div className='my-5'>

    <div className='md:text-[30px] font-bold text-[18px]  text-[green] text-center'>    Services We Offer</div>
            <hr className='w-[400px] font-bold mx-auto'  style={{ borderBottom: "4px solid #b13244" }}/>
    </div>
           
           
           
            
            <div className='grid grid-cols-2  md:grid-cols-4 px-3 md:px-0 gap-4  md:gap-12 justify-center mb-5'>
                {products.map((data, i) => {
                    return <Box {...data} key={i} />
                })}
            </div>
        </div>
        </div>
      
    );
}

export default Seller;

const Box = (props) => {
    
    return (
        <div style={{boxShadow:'  #129393 -5px 5px, #129393 -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px'}}  className=' p-4 overflow-hidden hover:shadow-lg'>
                <div className='max-w-[400px] h-[200px]'><img className='h-[100%] transition duration-300 ease-in-out hover:scale-110 w-[100%]' src={props.img} alt="" /></div>
                <div className='text-center'>
                    <h1 className='capitalize  text-[12px] md:text-[16px]  text-black my-4'>{props.name}</h1>
                    <div  class="bg-transparent text-[12px] text-bold md:text-[16px]  hover:bg-black text-[#e21756] font-semibold hover:text-white py-2 my-2 md:px-3 border border-blue-500 hover:border-transparent rounded">
                    <Link to={props.link}>View Full Details</Link>
                    </div>
                  
                </div>
            </div>
    );
};







﻿













