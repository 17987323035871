import React from 'react'
import Abouts from '../camponent/Abouts'

function About() {
  return (
   <>
   <Abouts/>
   </>
  )
}

export default About

