import React from 'react';
import Contacts from '../camponent/Contacts';

const Contact = () => {
    return (
        <>
            <Contacts/>
        </>
    );
}

export default Contact;
