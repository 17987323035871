import React from 'react';
import {FaShieldAlt,FaMoneyBillAlt} from "react-icons/fa";
import { IoBagCheckSharp } from "react-icons/io5";
import { FaUserCheck,FaHandshake } from "react-icons/fa6";


const Benefit = () => {
    const products = [
        {
            img: IoBagCheckSharp,
            name: "Start your Digital Financial Journey with Zero Investment",
            
        },
        {
            img: FaUserCheck,
            name: "Fast & Easy On-Boarding with KYC",
           
        },
        {
            img: FaShieldAlt,
            name: "100% Secure & Reliable Platform for all Digital Financial Services",
            
        },
        {
            img: FaMoneyBillAlt,
            name: "Best Margins promised on all Service Transactions",
          
        },
        {
            img: FaHandshake,
            name: "24/7 Multi-Lingual Technical Support",
            link: "/pan_card_issuance"
        },
       

        
    ];
    return (
        <div style={{boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px"}} className=' shadow-2xl px-4 w-full border min-h-[300px] '>
            <div className='w-full h-[45px]  mx-auto mt-10 font-sans font-bold text-[36px]  text-center text-[#194086]'>
                <span className=' font-sans  font-bold text-[36px] text-[#39b549]'>Benefit of</span> PayWorld
            </div>
            <div className='w-full px-2  md:mt-[20px] mt-[40px]  flex md:justify-between md:items-center flex-wrap md:flex-row flex-col justify-center items-center'>
               {
                products.map((item,index)=>{
                   return  <div className='border   w-full md:w-[210px] md:max-h-[210px] mb-10 hover:text-[white]  flex flex-col justify-center items-center px-2  shadow-lg duration-200'>
                    <div className='w-[79px] p-4 h-[79px] flex justify-center items-center   border border-[#39B549] myborder mt-5 justify-items-center rounded-full ' >
                    <item.img
                      className='w-[50px] text-[#194086] h-[50px]'/>
                    </div>
                    <div className='mt-6 text-center  text-[#194086]  text-[18px]'>{item.name}</div>
                    <div className='my-4 flex gap-3'><span className='text-[15px]  text-black'></span></div>
                </div>
                })
               }
            </div>

        </div>
    );
}

export default Benefit;
