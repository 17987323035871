import React from 'react';

const Abouts = () => {
    return (
        <div className='my-10' >
            <img className='w-full ' src="image/about-us.jpg" alt="" />
            <div className='w-full px-10'>
            <div style={{borderBottom:'4px double black'}} className='flex font-bold pb-4 max-w-[200px] gap-2'>
        <span className='text-[36px] text-[#39b549]'>Get</span>
        
        <span className='text-[#194086] text-[36px]'>inTouch</span>
      </div>
      <div style={{lineHeight:"40px"}} className='text-[17px] flex flex-col gap-14 text-justify items-center '>
        <p>SecurePe is an initiative from Sunrise Techno Solutions. It started its operations in the year 2021 and has made its presence known in India with over 100,000+ active retail touch-points across 33+ districts and 3 states. SecurePe was started with the idea of bridging the gap between the untouched market segments and the service providers.</p>
     <p> Using SecurePe's intelligent electronic transaction processing platform, the consumer can do transactions by visiting retail touchpoints and can pay digitally as well as through cash. We at SecurePe believe in the business philosophy of "Suraksha Aur Speed" and provide every possible opportunity to our retailers and distributors to earn with minimum investment and maximum return.</p>
       
      

     <p> Under this platform, SecurePe offers a plethora of utility services to its consumers in terms of AEPS, Domestic Money Remittance, Mobile and DTH recharges, Rail, Air and Bus reservation and Utility Bill collections, Digital Wallets, CMS (Cash Management Service) collection, Cash withdrawal points and Assisted e-commerce. Secure Pe also facilitate SME's loans. Approx. over 1 million transactions are done using this platform every year through our 100,000+ active retail touch points spread across India..</p>
      </div>
      <div className='mt-10'>
        <img src="image/juenry.jpg" alt="" />
      </div>
            </div>
        </div>
    );
}

export default Abouts;
