import React from 'react';
import Carousel from '../camponent/Carousel'


const Testmonial = () => {
    const videoMediaLinks = [
        {
            name: 'Facebook',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },
        {
            name: 'WhatsApp',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },
        {
            name: 'Instagram',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },
        {
            name: 'Facebook',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },
        {
            name: 'Facebook',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },
        {
            name: 'WhatsApp',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },{
            name: 'WhatsApp',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },{
            name: 'WhatsApp',
           src:"https://www.youtube.com/embed/PvcZqLnE2lY?si=gt_jubVsdP6L2NFx",
        },
    ];

    return (
        <>
       
         <div className='w-full'>
            <div>
                <h2 className=" text-center text-[#194086] font-bold mt-5 text-[36px] ">Testimonials</h2>
                <hr className='w-[40%] mb-8 text-1xl mx-auto'/>
                <div className="grid border border-red-400 grid-cols-1 gap-4 px-4 md:grid-cols-3 lg:grid-cols-4  py-10">
                    {videoMediaLinks.map((data, index) => (
                        <div key={index} className="flex justify-center items-center">
                           
<iframe  src={data.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className='w-[250px] h-[200px]' allowfullscreen></iframe>

                        </div>
                    ))}
                </div>
            </div>
        </div>
        </>
       
    );
}

export default Testmonial;
