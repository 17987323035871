import React from 'react';


const Mobile_Recharges = () => {
    return (
        <div className='px-6  mycontainer md:px-4 mb-8'>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
                <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/3.jpg" alt="" /></div>
                <div className='flex px-2 mt-10 md:mt-0 flex-col gap-3'>
                <p className="text-lg font-bold capitalize text-[#000]">
                <span className="text-mygreen">      Mobile &</span> 
    <span className="text-myblue">          DTH Recharges</span>
          </p>
    <hr />
    <p style={{lineHeight:'45px'}} className="text-sm text-black ">
Recharging Mobile and Data Cards are now easier than ever. Retailers can quickly recharge Mobile and Data Cards of all operators through the SecurePe portal. This facility provides customers with ease of access as they need to visit their nearest SecurePe retail outlet instead of searching for their operator's official store. SecurePe's integration with all major operators allows the recharges to happen on a real time basis.</p>
</div>

            </div>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
   
            <span className="text-mygreen">      Mobile &</span> 
    <span className="text-myblue">          DTH Recharges</span>
          </p>
    <hr />
    <p style={{lineHeight:'45px'}} className="text-sm text-black ">
Recharging Mobile and Data Cards are now easier than ever. Retailers can quickly recharge Mobile and Data Cards of all operators through the SecurePe portal. This facility provides customers with ease of access as they need to visit their nearest SecurePe retail outlet instead of searching for their operator's official store. SecurePe's integration with all major operators allows the recharges to happen on a real time basis.</p>
</div>
                <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/3.jpg" alt="" /></div>
            </div>

        </div>
    );
}

export default Mobile_Recharges;
