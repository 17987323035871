import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../camponent/Nav'
import Footer from '../camponent/Footer'


const Main =()=> {
  return (
   <>
   <Navbar/> 
   <Outlet/>
   <Footer/>
   </>
  )
}
export default Main
