import React from "react";

const Electricity_Bill = () => {
  return (
    <div className="px-6  mycontainer md:px-4 mb-8">
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] sm:w-[200px] flex justify-center items-center">
          <img src="image/8.jpg" alt="" />
        </div>
        <div className="flex mt-10 md:mt-0 px-2 flex-col gap-3">
          <p className="text-lg font-bold capitalize text-[#000]">
       
                
         <span className="text-mygreen">    Electricity Bill</span> 
    <span className="text-myblue">     Payments Service</span>
          </p>
          <hr />
          <p style={{lineHeight:'45px'}} className="text-sm text-black ">
            ➡ Pay your Electricity bills seamlessly by walking into a SecurePe
            outlets. Enjoy the convenience of paying the bills without having to
            go to the service provider's place. ➡ Pay bills to all India Billers
            Bill Amount is fetched automatically Pay bills anytime, anywhere No
            Billing amount Restrictions ➡ Key Benefits Easy, hassle-free and
            safe ➡ Convenient THE FUTURE OF FINTECH
          </p>
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg font-bold capitalize text-[#000]">
          <span className="text-mygreen">    Electricity Bill</span> 
    <span className="text-myblue">     Payments Service</span>
          </p>
          <hr />
          <p style={{lineHeight:'45px'}} className="text-sm text-black ">
            ➡ Pay your Electricity bills seamlessly by walking into a SecurePe
            outlets. Enjoy the convenience of paying the bills without having to
            go to the service provider's place. ➡ Pay bills to all India Billers
            Bill Amount is fetched automatically Pay bills anytime, anywhere No
            Billing amount Restrictions ➡ Key Benefits Easy, hassle-free and
            safe ➡ Convenient THE FUTURE OF FINTECH
          </p>
        </div>
        <div className="md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="image/8.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Electricity_Bill;
