import React, { createContext } from "react";
const MainContext = createContext();

const Context = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds a smooth scrolling animation
    });
  };
  const MenuItems = [
    {
      name: "Home",
      link: "/",
      status:1
    },
    {
      name: "About Us",
      link: "/about",
      status:1
    },
    {
      name: "vision&Mission",
      link: "/vision_mission",
      status:0
    },

    {
      name: "Services",
      link: "/services",
      status:1
    },

    {
      name: "Careers",
      link: "/careers",
      status:0
    },
    {
      name: "Contact",
      link: "/contact",
      status:1

    },
    {
      name: "Testmonial",
      link: "/testmonial",
      status:0

    },
  ];
  return (
    <MainContext.Provider value={{ scrollToTop, MenuItems }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default Context;
export { MainContext };
