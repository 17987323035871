import React, { useContext } from "react";
import { BsFacebook, BsWhatsapp, BsInstagram, BsYoutube } from "react-icons/bs";
import { AiOutlineArrowUp } from "react-icons/ai";

import { Link } from "react-router-dom";
import { MainContext } from "../context/context";

const Footer = () => {
  const { scrollToTop, MenuItems } = useContext(MainContext);
  const socialMediaLinks = [
    {
      name: 'Facebook',
      icon: BsFacebook,
      url: 'https://www.facebook.com/securepe.in',
      color:"text-[red]"
    },
    {
      name: 'WhatsApp',
      icon: BsWhatsapp,
      url: 'https://api.whatsapp.com/send/?phone=9799575786&text=Hello%2C+I+am+interested+in your services',
      color:"text-[blue]"
    },
    {
      name: 'Instagram',
      icon: BsInstagram,
      url: 'https://www.instagram.com/securepe.in/',
      color:"text-[pink]"

    },
  ];

  return (
    <div
      style={{ borderTop: "2px solid #b13244" }}
      className="mt-[50px]  w-mycontainer"
    >
      <footer className="relative bg-[#1F2126] text-white shadow-lg pt-8 pb-6">
        <div className="container mx-auto px-4">
          <div>
            <div className="grid gap-5 md:grid-cols-3">
              <div>
                <h4 className="text-3xl fonat-semibold text-blueGray-700">
                  Let's keep in touch!
                </h4>
                <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                  Find us on any of these platforms, we respond 1-2 business
                  days.
                </h5>
                <div className="mt-6 flex lg:mb-0 mb-6">
  {socialMediaLinks.map((socialMedia, index) => (
    <socialMedia.icon
      className={`${socialMedia.color} shadow-lg transition duration-300 ease-in-out hover:scale-110 font-normal text-[30px] items-center justify-center cursor-pointer align-center rounded-full outline-none focus:outline-none mr-2`}
      key={index}
    />
  ))}
</div>

              </div>
              <div className=" md:ml-10 ">
                <span className="block uppercase text-md font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled text-[12px]">
                  {MenuItems.map((menu, index)=> {
                     if(menu.status==1){
                      return (
                        <li key={index}>
                         
                            <Link
                            onClick={scrollToTop}
                            to={menu.link}
                            className="text-blueGray-600 hover:text-blueGray-800 block pb-2 text-sm"
                          >
                            {menu.name}
                          </Link>
                          
                          
                        </li>
                      );
                     }
                    
                  })}
                </ul>
              </div>
              <div>
                <span className="block uppercase text-[16px] ]font-semibold mb-2">
                  Office Address
                </span>
                <ul className="list-unstyled text-sm ">
                  <li>
                    {" "}
                    46, 3rd Floor, Precious Mall, MD Road, Jaipur, Rajasthan -
                    302004{" "}
                  </li>
                  <li className="mt-2">Info@securepe.in</li>
                  <li className="mt-2">+91-8952996656</li>
                  <li className="mt-2">+91-9799575786</li>
                </ul>
              </div>
            </div>
            <div className="container flex justify-end">
              <button
                onClick={scrollToTop}
                className="scroll-to-top-button   inline-block "
              >
                <AiOutlineArrowUp className="w-[30px]  rounded-full border-[#b13244] border  text-center animate-bounce h-[30px] text-[#b13244]" />
              </button>
            </div>
          </div>
          <hr className="my-2 " style={{ borderBottom: "2px solid #b13244" }} />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-full px-2 mx-auto text-center">
              <div className="text-sm uppercase text-blueGray-500 font-semibold ">
                Copyright © 2023 www.securepe.in
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
