import React from 'react';

const White_Software = () => {
    return (
        <div className='px-6 mycontainer  md:px-0 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/6.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
            <span className="text-mygreen">White</span> 
    <span className="text-myblue"> Label Software</span>
          </p>
                <hr />
                <p style={{lineHeight:'45px'}} className="text-sm text-black ">White Label Software Since Its Good decision to start your own AEPS, Money Transfer, Mobile recharge Portal. Developing your own B2B portal helps you to easily create and maintain Retailers, Distributors and Master Distributors but developing your own B2B Portal is not that easy task. It consists a few complex phases: 
                •Software Development API Integration
                •Application Development
                </p>

            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg font-bold capitalize text-[#000]">
         
            <span className="text-mygreen">White</span> 
    <span className="text-myblue"> Label Software</span>
          </p>
                <hr />
               <p style={{lineHeight:'45px'}} className="text-sm text-black ">White Label indicates that it has no label. You can easily label it for yourself. We at SecurePe provide White Label Portal. You're Own Brand, Your own Domain Name and Your Own Logo choose your own website color combination and write your own company information in about us, contact us and other pages. We will provide an admin panel, with your admin control panel you can create unlimited super distributors, distributors, retailers. We provide a complete business solution for your business which includes 100+ IT Services such as AEPS, DMT, Recharges, PAN card Issuance, BBPS, Motor Insurance, Loan, Utility bill Payments Service Etc.</p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/6.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default White_Software;







