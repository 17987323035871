import React from "react";

const Adharcard_Payment = () => {
  return (
    <div className="px-6 mycontainer md:px-4 mb-8">
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] myshadow  sm:w-[200px] flex justify-center items-center">
          <img src="image/1.jpg" alt="" />
        </div>
        <div className="flex px-2 mt-10 md:mt-0 flex-col gap-3">
          <p className="text-lg font-bold capitalize text-[#000]">
         <span className="text-mygreen">Aadhaar Enabled</span> 
         <span className="text-myblue"> Payment System</span>
          </p>
          <hr />
          <p style={{lineHeight:'45px'}} className="text-sm text-black ">
          Why go searching for your bank when you have SecurePe Retail touchpoint next door for all your banking needs? Aadhaar Enabled Payment Service (AEPS), uses Aadhaar Data and Biometric authentication instead of signature or debit card information of the customers and allows them to quickly Withdraw, Deposit and Transfer cash & also access bank statements without visiting their bank branch. So, instead of searching for an ATM or a bank branch, customers can visit their nearest SecurePe retail touchpoints and do all of these transactions using their Aadhaar Data and Biometric Authentication.
          </p>
        
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg capitalize font-bold text-[#000]">
          <span className="text-mygreen">Aadhaar Enabled</span> 
         <span className="text-myblue"> Payment System</span>
          </p>
          <hr />
          <p style={{lineHeight:'45px'}} className="text-sm text-black ">
          Why go searching for your bank when you have SecurePe Retail touchpoint next door for all your banking needs? Aadhaar Enabled Payment Service (AEPS), uses Aadhaar Data and Biometric authentication instead of signature or debit card information of the customers and allows them to quickly Withdraw, Deposit and Transfer cash & also access bank statements without visiting their bank branch. So, instead of searching for an ATM or a bank branch, customers can visit their nearest SecurePe retail touchpoints and do all of these transactions using their Aadhaar Data and Biometric Authentication.
          </p>
         
        </div>
        <div className="md:w-[400px] mt-10 md:mt-0  md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="image/1.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Adharcard_Payment;




