import React, { useState, useContext } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MainContext } from "../context/context";


const Header = () => {
  const [toggle, settoggle] = useState(false);
  const { MenuItems } = useContext(MainContext);

  const closeMenu = () => {
    settoggle(false);
  };

  return (
    <div
      style={{ borderBottom: "2px solid #b13244" }}
      className="mycontainer shadow-lg"
    >
      <nav className="bg-white">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
          <div className="flex items-center">
            <Link to="/">
             <img src="image/logo.jpg" className="w-[100px] h-[60px]" alt="" />
            </Link>
          </div>
          <AiOutlineMenu
            onClick={() => {
              settoggle(true);
            }}
            className="md:hidden  text-[black] text-[30px]"
          />
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium uppercase text-[18px] text-[black] items-center  flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-[10px] lg:space-x-[30px] md:mt-0 md:border-0">
              {MenuItems.map((page, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={page.link}
                      onClick={closeMenu}
                      className="block py-2 pl-3 pr-4 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[blue] md:p-0 "
                    >
                      {page.name}
                    </Link>
                  </li>
                );
              })}
              <li>
                <div className="text-white p-3 flex bg-gray-800 hover:bg-[#b13244]  focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm dark:bg-gray-800 dark:focus:ring-gray-700 dark:border-gray-700">
                  <Link
                    to="https://api.whatsapp.com/send/?phone=9799575786&text=Hello%2C+I+am+interested+in your services "
                    className="block md:hover:bg-transparent md:border-0 hover:bg-[#b13244]  hover:text-[white] zoom-hover"
                  >
                    <BsWhatsapp className="text-[18px] animate-bounce mr-2 hover:text-[#b13244] float-left" />
                    ChatNow
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full md:hidden md:w-auto" id="navbar-default">
            <ul
              style={{ zIndex: "99999" }}
              className={`md:hidden fixed w-screen h-[100vh] bg-[rgba(0,0,0,0.7)] text-white flex flex-col gap-8  items-center justify-center pt-6  duration-300 top-[0px]
                ${toggle === true ? "left-[0%]" : "left-[-100%]"}
                `}
            >
              {MenuItems.map((page, index) => {
                return (
                  <li key={index}>
                    <Link
                      to={page.link}
                      onClick={closeMenu}
                      className="block py-2 pl-3 pr-4 text-[white] hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  md:dark:hover:text-blue-500 zoom-hover"
                    >
                      {page.name}
                    </Link>
                  </li>
                );
              })}
              <li>
                <AiOutlineClose
                  className="text-[30px] text-[black]"
                  onClick={() => {
                    settoggle(false);
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
